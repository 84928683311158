/* eslint-disable react/prop-types */
import {
    LinearProgress,
    ListItemIcon,
    MenuItem,
} from '@mui/material';
import type { PaginationState } from '@tanstack/react-table';
import { selectUser } from 'global-state/selectors';
import { validateEmail, validateRequired } from 'helpers/validation.helpers';
import MaterialReactTable, {
    MRT_Cell,
    MRT_ColumnDef,
} from 'material-react-table';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserRoleEnum } from 'types/user-role.enum';
import { listNotifications } from '../../api/notifications/notifications.api';
import { CurrentNotificationInfoDTO, Notifications } from '../../types/notifications.dto';
import { firstLetterUppercase } from '../../utilities/i18n/helpers';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { StyledP, StyledTitleContainer } from '../../design/theme/shared-style';
import { UserStatusEnum, UserStatusEnumSpanish } from '../../types/user-status.enum';
import { LastLoginValue, SelectedValue } from '../../enums/dropdown.enum';

const NotificationsList: FC<{
    openEdit: (currentNotificationInfo: CurrentNotificationInfoDTO) => void,
    reloadList: boolean,
    deleteNotification: (notificationId: string) => void,
    filterByCompany?: string
}> = ({ openEdit, reloadList, deleteNotification, filterByCompany }) => {
    const [tableData, setTableData] = useState<Notifications[]>([]);
    const [validationErrors, setValidationErrors] = useState<{
        [cellId: string]: string;
    }>({});
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const loggedUser = useSelector(selectUser);

    useEffect(() => {
        loadNotificationsData();
    }, [reloadList])

    const loadNotifications = async (notifications: Notifications[]) => {
        if (notifications && notifications.length > 0) {
            setTableData(notifications);
        } else if (notifications && notifications.length === 0) {
            setTableData([]);
        }
    }

    const loadNotificationsData = async () => {
        setLoading(true);
        const notifications = await listNotifications(filterByCompany);
        await loadNotifications(notifications);
        setLoading(false);
    }

    const getCommonEditTextFieldProps = useCallback(
        (
            cell: MRT_Cell<Notifications>,
        ): MRT_ColumnDef<Notifications>['muiTableBodyCellEditTextFieldProps'] => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo<MRT_ColumnDef<Notifications>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                size: 250,
                hidden: true,
                enableHiding: false,
                enableColumnActions: false,
                enableSorting: false,
                enableColumnOrdering: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'status',
                header: 'Estado',
                size: 140,
                enableColumnActions: false,
                enableSorting: false,
                enableColumnOrdering: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: (props) => {
                    if (
                      props.cell.getValue<string>() === 'SENT'
                    ) {
                        return <span>✅ Enviada</span>;
                    } else if(props.cell.getValue<string>() === 'PENDING') {
                        return <span>⏳ Pendiente</span>;
                    } else {
                        return <span>--</span>;
                    }
                },
            },
            {
                accessorKey: 'title',
                header: 'Título',
                size: 140,
                enableColumnActions: false,
                enableSorting: false,
                enableColumnOrdering: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: (props) => {
                    if (
                        props.cell.getValue<string>() &&
                        props.cell.getValue<string>() !== 'null'
                    ) {
                        return <span>{props.cell.getValue<string>()}</span>;
                    } else {
                        return <span>--</span>;
                    }
                },
            },
            {
                accessorKey: 'body',
                header: 'Mensaje',
                enableColumnActions: false,
                enableSorting: false,
                enableColumnOrdering: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: (props) => {
                    if (
                        props.cell.getValue<string>() &&
                        props.cell.getValue<string>() !== 'null'
                    ) {
                        if (props.cell.getValue<string>().length > 60) {
                            return <span>{props.cell.getValue<string>().slice(0, 60)}...</span>;
                        } else {
                            return <span>{props.cell.getValue<string>()}</span>;
                        }
                    } else {
                        return <span>--</span>;
                    }
                },
            },
            ...(loggedUser && loggedUser.role === UserRoleEnum.ADMIN ? [{
                accessorKey: 'company.comercialName',
                header: 'Empresa',
                enableColumnActions: false,
                enableSorting: false,
                enableColumnOrdering: false,
                Cell: ({ cell }: { cell: MRT_Cell<Notifications> }) => {
                    if (
                        cell.getValue<string>() &&
                        cell.getValue<string>() !== 'null'
                    ) {
                        return <span>{cell.getValue<string>()}</span>
                    } else {
                        return <span>Todas las empresas</span>
                    }
                },
            }] : []),
            {
                accessorKey: 'filters.area',
                header: 'Área',
                enableColumnActions: false,
                enableSorting: false,
                enableColumnOrdering: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: (props) => {
                    if (
                        props.cell.getValue<string>() &&
                        props.cell.getValue<string>() !== 'null'
                    ) {
                        return <span>{firstLetterUppercase(props.cell.getValue<string>())}</span>;
                    } else {
                        return <span>--</span>;
                    }
                },
            },
            {
                accessorKey: 'filters.location',
                header: 'Ubicación',
                enableColumnActions: false,
                enableSorting: false,
                enableColumnOrdering: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: (props) => {
                    if (
                        props.cell.getValue<string>() &&
                        props.cell.getValue<string>() !== 'null'
                    ) {
                        return <span>{firstLetterUppercase(props.cell.getValue<string>())}</span>;
                    } else {
                        return <span>--</span>;
                    }
                },
            },
            {
                accessorKey: 'filters.profile',
                header: 'Perfil',
                enableColumnActions: false,
                enableSorting: false,
                enableColumnOrdering: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: (props) => {
                    if (
                      props.cell.getValue<string>() &&
                      props.cell.getValue<string>() !== 'null' &&
                      props.cell.getValue<string>() !== SelectedValue.ALL
                    ) {
                        return <span>{firstLetterUppercase(props.cell.getValue<string>())}</span>;
                    } else if (props.cell.getValue<string>() === SelectedValue.ALL) {
                        return <span>Todos</span>;
                    } else {
                        return <span>--</span>;
                    }
                },
            },
            {
                accessorKey: 'filters.lastLoginDate',
                header: 'Último inicio de sesión',
                enableColumnActions: false,
                enableSorting: false,
                enableColumnOrdering: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: (props) => {
                    if (
                      props.cell.getValue<string>() &&
                      props.cell.getValue<string>() !== 'null'
                    ) {
                        const lastLoginDateSelected = props.cell.getValue<string>();
                        if(lastLoginDateSelected === SelectedValue.ALL) {
                            return <span>Todos</span>;
                        } else if(lastLoginDateSelected === LastLoginValue.WITH_ACCESS_LAST_30_DAYS) {
                            return <span>Últimos 30 días</span>;
                        } else {
                            return <span>Más de 30 días</span>;
                        }
                    } else {
                        return <span>--</span>;
                    }
                },
            },
            {
                accessorKey: 'filters.status',
                header: 'Estado',
                enableColumnActions: false,
                enableSorting: false,
                enableColumnOrdering: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: (props) => {
                    const statusFilterSelected = props.cell.getValue<string>();
                    if (
                      statusFilterSelected &&
                      statusFilterSelected !== 'null' &&
                      statusFilterSelected !== SelectedValue.ALL
                    ) {
                        const userStatus = statusFilterSelected as UserStatusEnum;
                        return <span>{firstLetterUppercase(UserStatusEnumSpanish[userStatus])}</span>;
                    } else if (statusFilterSelected === SelectedValue.ALL) {
                        return <span>Todos</span>;
                    } else {
                        return <span>--</span>;
                    }
                },
            },
            {
                accessorKey: 'createdAt',
                header: 'Fecha de envío',
                size: 140,
                enableColumnActions: false,
                enableSorting: false,
                enableColumnOrdering: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: (props) => {
                    if (
                        props.cell.getValue<string>() &&
                        props.cell.getValue<string>() !== 'null'
                    ) {
                        const formattedDate = moment.utc(props.cell.getValue<string>()).format('DD/MM/YYYY');
                        return <span>{formattedDate}</span>;
                    } else {
                        return <span>--</span>;
                    }
                },
            },
            {
                accessorKey: 'recipientCount',
                header: 'Receptores',
                enableColumnActions: false,
                enableSorting: false,
                enableColumnOrdering: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: (props) => {
                    if (
                      props.cell.getValue<string>() &&
                      props.cell.getValue<string>() !== 'null' &&
                      (props.row.original as any).status && (props.row.original as any).status !== 'PENDING'
                    ) {
                        return <span>{firstLetterUppercase(props.cell.getValue<string>())}</span>;
                    } else {
                        return <span>--</span>;
                    }
                },
            },
        ],
        [getCommonEditTextFieldProps],
    );
    return (
        <div style={{ width: '100%' }}>
            {loading && tableData.length === 0 && <LinearProgress color="info" />}
            {tableData && tableData.length > 0 && (
                <MaterialReactTable
                    initialState={{ density: 'compact' }}
                    enableStickyHeader
                    enableStickyFooter
                    columns={columns}
                    defaultColumn={{
                        minSize: 20,
                        maxSize: 160,
                        size: 50,
                    }}
                    data={tableData}
                    muiTablePaperProps={{
                        elevation: 0,
                        sx: {
                            borderRadius: '0',
                        },
                    }}
                    enableRowActions={true}
                    enableRowSelection={false}
                    enableRowOrdering={false}
                    enableHiding={false}
                    enableFullScreenToggle={false}
                    enableMultiRowSelection={false}
                    positionActionsColumn="first"
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                        <MenuItem
                            key={0}
                            onClick={() => {
                                openEdit({
                                    currentTitle: row.original.title,
                                    currentBody: row.original.body,
                                    currentArea: row.original.filters.area,
                                    currentLocation: row.original.filters.location,
                                    notificationId: row.original.id,
                                    notificationCompanyName: row.original.company?.comercialName
                                });
                                closeMenu();
                            }}
                            sx={{ m: 0 }}
                        >
                            <ListItemIcon>
                                <BorderColorIcon />
                            </ListItemIcon>
                            Modificar
                        </MenuItem>,
                        <MenuItem
                            key={1}
                            onClick={() => {
                                deleteNotification(row.original.id);
                                closeMenu();
                            }}
                            sx={{ m: 0 }}
                        >
                            <ListItemIcon>
                                <DeleteIcon />
                            </ListItemIcon>
                            Eliminar
                        </MenuItem>,
                    ]}
                    onPaginationChange={setPagination}
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [10, 20, 50],
                    }}
                    state={{
                        isLoading: loading,
                        pagination,
                        columnVisibility: { id: false },
                    }}
                />
            )}
            {!loading && tableData && tableData.length === 0 && (
                <StyledTitleContainer>
                    <StyledP>No se encontraron notificaciones.</StyledP>
                </StyledTitleContainer>
            )}
        </div>
    );
};

export default NotificationsList;
