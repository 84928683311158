import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { toTitleCase } from 'helpers/text.helpers';
import { useEffect, useState } from 'react';

interface Company {
  id: string;
  entityName: string;
}

interface CompaniesDropDownProps {
  companies: Company[];
  setCompanyFromDropDown: (id: string) => void;
  disableMargin?: boolean;
  disableWidth?: boolean;
  defaultOptionRequired?: boolean;
}

export default function CompaniesDropDown(props: CompaniesDropDownProps) {
  const [inputValue, setInputValue] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [styleDropdown, setStyleDropdown] = useState<React.CSSProperties>({});

  useEffect(() => {
    let companiesList = props.companies;
    if (props.defaultOptionRequired) {
      companiesList = [{ id: 'all', entityName: 'Todas las empresas' }, ...props.companies];
      setSelectedCompany({ id: 'all', entityName: 'Todas las empresas' });
    }
    setCompanies(companiesList);
  }, [props.companies, props.defaultOptionRequired]);

  useEffect(() => {
    if (!props.disableMargin) {
      setStyleDropdown({ marginBottom: '40px', marginTop: '40px', width: '400px' });
    } else if (!props.disableWidth) {
      setStyleDropdown({ width: '400px' });
    }
  }, [props.disableMargin, props.disableWidth]);

  return (
    <Box style={styleDropdown}>
      <Autocomplete
        value={selectedCompany}
        options={companies}
        getOptionLabel={(option: Company) => toTitleCase(option.entityName)}
        style={{ width: '100%' }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Buscar por empresa" variant="outlined" />
        )}
        onChange={(event, newValue: Company | null) => {
          if (!newValue && props.defaultOptionRequired) {
            return;
          }
          setSelectedCompany(newValue);
          props.setCompanyFromDropDown(newValue?.id || '');
        }}
        disableClearable={props.defaultOptionRequired}
      />
    </Box>
  );
}